import React, {ChangeEventHandler, Fragment, ReactNode, useEffect, useState,} from "react";
import TileRounded from "../layout/TileRounded";
import TileBgNone from "../layout/TileBgNone";
import Spinner from "../animation/Spinner";
import {CalendarIcon, DownloadIcon,} from "@heroicons/react/outline";
import {Popover, Transition, Tab} from "@headlessui/react";
import {format, isValid, parse} from "date-fns";
import {DateRange, DayPicker, SelectSingleEventHandler} from "react-day-picker";
import "react-day-picker/dist/style.css";
import {ExclamationCircleIcon, SearchIcon, XIcon} from "@heroicons/react/solid";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {getDateFromApiString, SentimentType} from "../../util/api";
import {ContentDataType, getContentDataTypeById, getEnabledContentDataTypes} from "../../models/content";
import {
    ContentArxivPaper,
    ContentEditorialPost,
    ContentPost,
    ContentRedditPost,
    ContentTweet,
    DataType
} from "../../models/contentApi.model";
import {useGetContentByDate, useGetRecentContentData} from "./getContentPosts";
import ContentGenericItem from "./ContentGenericItem";
import ContentTwitterItem from "./ContentTwitterItem";
import ContentRedditItem from "./ContentRedditItem";
import ContentEditorialItem from "./ContentEditorialItem";
import ContentArxivPaperItem from "./ContentArxivPaperItem";
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    SearchBox,
    Hits,
    RefinementList,
    Configure,
    Stats, InfiniteHits, useInfiniteHits
} from 'react-instantsearch';
import SearchHit from "./SearchHit";

const SubmitIcon = () => (
    <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
);

const ResetIcon = () => (
    <XIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ContentStream = ({ isSubjectLoading, dashboardId, subjectData }: DashboardViewProps) => {
    const enabledDataTypes: ContentDataType[] = getEnabledContentDataTypes(subjectData!)
    const [contentItems, setContentItems] = useState<ContentPost[]>([]);
    const [dateApi, setDateApi] = useState<string | null>(null);
    const [offset, setOffset] = useState<number | null>(null);
    const [topicId, setTopicId] = useState<number>(0);
    const [searchTopicId, setSearchTopicId] = useState<number>(0);
    const [sentiment, setSentiment] = useState<SentimentType>(SentimentType.ANY);
    const [doDisplayDefaultTweets, setDoDisplayDefaultTweets] =
        useState<boolean>(true);
    const [contentTypeFilter, setContentTypeFilter] = useState<number>(0);
    const [searchContentTypeFilter, setSearchContentTypeFilter] = useState<number>(0);
    const [isTweetsLoaded, setIsTweetsLoaded] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [inputDateValue, setInputDateValue] = useState<string>("");
    const [isDateError, setIsDateError] = useState<boolean>(false);
    const [recentContent, setRecentContent] = useState<ContentPost[]>([])
    const [isRecentTweetsLoaded, setIsRecentTweetsLoaded] = useState<boolean>(false);
    const [searchClient, setSearchClient] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<'stream' | 'search'>('stream');

    // Search dates
    const [searchDateApi, setSearchDateApi] = useState<string | null>(null);
    const [selectedSearchDateRange, setSelectedSearchDateRange] = useState<DateRange>();
    const [selectedSearchEndDate, setSelectedSearchEndDate] = useState<Date>();
    const [selectedSearchStartDate, setSelectedSearchStartDate] = useState<Date>();
    const [inputSearchStartDateValue, setInputSearchStartDateValue] = useState<string>("");
    const [inputSearchEndDateValue, setInputSearchEndDateValue] = useState<string>("");
    const [isSearchStartDateError, setIsSearchStartDateError] = useState<boolean>(false);
    const [isSearchEndDateError, setIsSearchEndDateError] = useState<boolean>(false);

    const getSelectedContentDataTypeName = (): string | null => {
        if (contentTypeFilter && getContentDataTypeById(contentTypeFilter)) {
            return getContentDataTypeById(contentTypeFilter)!.data_type
        }
        return null;
    }

    const sortContentItems = (content_posts: ContentPost[]) => {
        const sorted_posts: ContentPost[] = []
        for (const post of content_posts) {
            if (!sorted_posts.find(p => p.content.id == post.content.id)) {
                sorted_posts.push(post)
            }
        }
        return sorted_posts.sort((a, b) => {
            const dateA = getDateFromApiString(a.created_at).toLocal();
            const dateB = getDateFromApiString(b.created_at).toLocal();
            return dateB.toUnixInteger() - dateA.toUnixInteger();
        })
    }

    const getPostNode = (content: ContentPost, wrapperClass: string) => {
        if (content.content.data_type === DataType.Tweet) {
            return <ContentTwitterItem key={content.content.id} contentData={content as ContentTweet}  wrapperClass={wrapperClass} />
        }
        if (content.content.data_type === DataType.RedditPost) {
            return <ContentRedditItem key={content.content.id} contentData={content as ContentRedditPost}  wrapperClass={wrapperClass} />
        }
        if ([DataType.Blogs, DataType.News, DataType.Discussions].some(type => type === content.content.data_type)) {
            return <ContentEditorialItem key={content.content.id} contentData={content as ContentEditorialPost}  wrapperClass={wrapperClass} />
        }
        if (content.content.data_type === DataType.ArxivPaper) {
            return <ContentArxivPaperItem key={content.content.id} contentData={content as ContentArxivPaper}  wrapperClass={wrapperClass} />
        }
        return <ContentGenericItem key={content.content.id} contentData={content}  wrapperClass={wrapperClass}/>
    }

    const {
        isLoading: isLoadingTweets,
        data: tweetData,
        error: tweetError,
        execute,
    } = useGetContentByDate();

    const {
        isLoading: isLoadingRecentTweets,
        data: recentTweetData,
        error: recentTweetError,
        execute: recentExecute,
    } = useGetRecentContentData();

    useEffect( () => {
        if (dateApi) {
            return;
        }
        recentExecute(dashboardId, topicId, sentiment != SentimentType.ANY ? sentiment : null, getSelectedContentDataTypeName()).then((response) => {
            setRecentContent(sortContentItems(response?.content ?? []))
            setIsRecentTweetsLoaded(true)
        })
    }, [dashboardId, topicId, sentiment, contentTypeFilter])

    useEffect( () => {
        if (isSubjectLoading) {
            return;
        }
        if (!subjectData?.doEnableAlgoliaSearch) {
            return;
        }
        const algoliaData = subjectData?.algoliaData;
        if (!algoliaData) {
            return;
        }
        const searchClient = algoliasearch(algoliaData.appId, algoliaData.searchApiKey);
        setSearchClient(searchClient)
    }, [dashboardId, isSubjectLoading])

    useEffect(() => {
        if (dateApi === null) {
            return;
        }
        setOffset(null);
        setIsTweetsLoaded(false);
        execute(
            dashboardId,
            dateApi,
            null,
            topicId === 0 ? null : topicId,
            sentiment != SentimentType.ANY ? sentiment : null,
            getSelectedContentDataTypeName()
        ).then((response) => {
            setContentItems(sortContentItems(response?.content ?? []));
            if (response?.isNext) {
                setIsTweetsLoaded(true);
                setOffset(response?.nextOffset ?? null);
            }
        });
        return () => {};
    }, [dateApi, dashboardId, topicId, sentiment, contentTypeFilter]);

    const loadMoreTweets = () => {
        if (dateApi === null) {
            return;
        }
        execute(
            dashboardId,
            dateApi,
            offset,
            topicId === 0 ? null : topicId,
            sentiment != SentimentType.ANY ? sentiment : null,
            getSelectedContentDataTypeName()
        ).then((response) => {
            if (response) {
                setContentItems(sortContentItems([...contentItems, ...response.content]));
            }
            if (response?.isNext) {
                setIsTweetsLoaded(true);
                setOffset(response?.nextOffset ?? null);
            } else {
                setOffset(null);
            }
        });
    };

    const onFilterChange = () => {
        setOffset(null)
    }

    const getToggles = () => {
        if (doDisplayDefaultTweets || isLoadingTweets || isSubjectLoading) {
            return null;
        }
        return (
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <button
                        type={"button"}
                        className="inline-flex items-center px-4 py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => resetDate()}
                    >
                        Reset Date
                    </button>
                </div>
            </div>
        );
    };

    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noTweetsJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedDate(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            setIsDateError(false);
            setSelectedDate(date);
            setDateApi(e.currentTarget.value);
            setIsTweetsLoaded(false);
            setDoDisplayDefaultTweets(false);
        } else {
            setIsDateError(true);
            setSelectedDate(undefined);
        }
    };


    const handleSearchStartInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputSearchStartDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedSearchDateRange(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            if (selectedSearchEndDate) {
                if (selectedSearchEndDate >= date) {
                    setSelectedSearchDateRange({
                        from: date,
                        to: selectedSearchEndDate,
                    });
                    setIsSearchEndDateError(false);
                    setIsSearchStartDateError(false);
                } else {
                    setIsSearchStartDateError(true);
                    setSelectedSearchDateRange(undefined);
                }
            }
            setSelectedSearchStartDate(date)
        } else {
            setIsSearchStartDateError(true);
            setSelectedSearchDateRange(undefined);
        }
    };

    const handleSearchEndInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputSearchEndDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedSearchDateRange(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            if (selectedSearchStartDate) {
                if (selectedSearchStartDate <= date) {
                    setSelectedSearchDateRange({
                        from: selectedSearchStartDate,
                        to: date,
                    });
                    setIsSearchEndDateError(false);
                    setIsSearchStartDateError(false);
                } else {
                    setIsSearchEndDateError(true);
                    setSelectedSearchDateRange(undefined);
                }
            }
            setSelectedSearchEndDate(date)
        } else {
            setIsSearchEndDateError(true);
            setSelectedSearchDateRange(undefined);
        }
    };

    const handleDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedDate(date);
            if (date) {
                const dateString = format(date, "y-MM-dd");
                setInputDateValue(dateString);
                setIsDateError(false);
                setDateApi(dateString);
                setIsTweetsLoaded(false);
                setDoDisplayDefaultTweets(false);
                closeCallBack();
            } else {
                setInputDateValue("");
            }
        };
    };

    const handleSearchDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedSearchDateRange(date);
            if (date && date.to && date.from) {
                console.log(date)
                const dateStartString = format(date.from, "y-MM-dd");
                const dateEndString = format(date.to, "y-MM-dd");
                setInputSearchStartDateValue(dateStartString);
                setInputSearchEndDateValue(dateEndString);
                setSelectedSearchStartDate(date.from)
                setSelectedSearchEndDate(date.to)
                setIsSearchStartDateError(false);
                setIsSearchEndDateError(false);
                closeCallBack();
            } else {
                setInputSearchStartDateValue("");
                setInputSearchEndDateValue("");
            }
        };
    };

    const resetDate = () => {
        setInputDateValue("");
        setSelectedDate(undefined);
        setIsDateError(false);
        setIsTweetsLoaded(false);
        setDoDisplayDefaultTweets(true);
        setDateApi(null);
        setOffset(null);
    };

    const resetSearchDate = (close) => {
        setInputSearchStartDateValue("");
        setInputSearchEndDateValue("");
        setSelectedSearchDateRange(undefined);
        setSelectedSearchStartDate(undefined)
        setSelectedSearchEndDate(undefined)
        setIsSearchStartDateError(false);
        setIsSearchEndDateError(false);
        close();
    }

    const getSearchRangeCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        if (isSearchStartDateError) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right"}>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-1 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full xl:-translate-x-1/2 transform px-4 sm:px-0">
                                        <div
                                            className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-4">
                                            <div className={""}>
                                                <button
                                                    type={"button"}
                                                    className="inline-flex items-center px-3 py-2  border border-blue-300 shadow-sm text-sm font-medium rounded text-gray-100 bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    onClick={() => resetSearchDate(close)}
                                                >
                                                    Reset Date
                                                </button>
                                            </div>
                                            <DayPicker
                                                initialFocus={open}
                                                mode="range"
                                                selected={selectedSearchDateRange}
                                                onSelect={handleSearchDaySelect(close)}
                                            />
                                            <div className={"flex mt-1"}>
                                                <div>
                                                    <label htmlFor={`search-start-date`}
                                                           className={"block text-sm text-gray-500"}>
                                                        <p className="sr-only">Enter Start Date in YYYY-MM-DD format</p>
                                                        Start Date
                                                    </label>
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name={`search-start-date`}
                                                            id={`search-start-date`}
                                                            className={inputClass}
                                                            placeholder={format(new Date(), "y-MM-dd")}
                                                            value={inputSearchStartDateValue}
                                                            onChange={handleSearchStartInputChange}
                                                            aria-invalid="true"
                                                            aria-describedby={isSearchStartDateError ? `social-search-start-date-error` : undefined}
                                                        />
                                                        {isSearchStartDateError ? (
                                                            <div
                                                                className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {isSearchStartDateError ? (
                                                        <p className="mt-1 text-xs text-red-600"
                                                           id={`social-search-start-date-error`}>
                                                            Invalid date (YYYY-MM-DD format) must be before start date
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className={"pl-4"}>
                                                    <label htmlFor={`search-end-date`}
                                                           className={"block text-sm text-gray-500"}>
                                                        <p className="sr-only">Enter End Date in YYYY-MM-DD format</p>
                                                        End Date
                                                    </label>
                                                    <div className="mt-1 relative rounded-md shadow-sm">
                                                        <input
                                                            type="text"
                                                            name={`search-end-date`}
                                                            id={`search-end-date`}
                                                            className={inputClass}
                                                            placeholder={format(new Date(), "y-MM-dd")}
                                                            value={inputSearchEndDateValue}
                                                            onChange={handleSearchEndInputChange}
                                                            aria-invalid="true"
                                                            aria-describedby={isSearchEndDateError ? `social-search-start-date-error` : undefined}
                                                        />
                                                        {isSearchEndDateError ? (
                                                            <div
                                                                className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {isSearchEndDateError ? (
                                                        <p className="mt-1 text-xs text-red-600"
                                                           id={`social-search-end-date-error`}>
                                                            Invalid date (YYYY-MM-DD format) must be after start date
                                                        </p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    }

    const getCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        const isDateErrorCalendar = isDateError;
        if (isDateErrorCalendar) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right"}>
                <div className={"flex-1 mt-1"}>
                    <label htmlFor={`stream-date`} className="sr-only">
                        Enter Date in YYYY-MM-DD format
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name={`stream-date`}
                            id={`search-date`}
                            className={inputClass}
                            placeholder={format(new Date(), "y-MM-dd")}
                            value={inputDateValue}
                            onChange={handleInputChange}
                            aria-invalid="true"
                            aria-describedby={isDateError ? `social-stream-date-error` : undefined}
                        />
                        {isDateErrorCalendar ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : null}
                    </div>
                    {isDateErrorCalendar ? (
                        <p className="mt-1 text-sm text-red-600 absolute" id={`social-stream-date-error`}>
                            Invalid date (YYYY-MM-DD format)
                        </p>
                    ) : null}
                </div>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full xl:-translate-x-1/2 transform px-4 sm:px-0">
                                        <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <DayPicker
                                                initialFocus={open}
                                                mode="single"
                                                defaultMonth={selectedDate}
                                                selected={selectedDate}
                                                onSelect={handleDaySelect(close)}
                                            />
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    };

    const getLoadMoreButton = () => {
        if (doDisplayDefaultTweets || !offset) {
            return null;
        }
        return (
            <div>
                <button
                    disabled={isLoadingTweets}
                    onClick={loadMoreTweets}
                    className={`${
                        isLoadingTweets
                            ? "text-gray-500 bg-gray-50"
                            : "text-gray-700 bg-white hover:bg-gray-50"
                    }
             inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                >
                    {isLoadingTweets ? (
                        <Spinner width={12} height={12} fill={"#5046e5"} />
                    ) : (
                        <DownloadIcon className={"w-6 h-5 text-gray-400"} />
                    )}
                    <div className={"inline-block pl-1"}>Load More</div>
                </button>
            </div>
        );
    };

    const getTweetRender = () => {
        if (!isRecentTweetsLoaded || isSubjectLoading) {
            return loadingJsx;
        }
        if (doDisplayDefaultTweets) {
            if (!recentContent || recentContent.length === 0) {
                return noTweetsJsx("no recent posts found");
            } else {
                const defaultTweets: ReactNode[] = [];
                recentContent.forEach((postData, index) => {
                    if (!postData.content.data_type !== DataType.News) {
                        defaultTweets.push(
                            getPostNode(postData, "border-b-2 border-gray-100 pb-4 pt-4 break-words")
                        );
                    }
                });
                return <div className={"pb-4"}>{defaultTweets}</div>;
            }
        } else if (isLoadingTweets && !isTweetsLoaded) {
            return loadingJsx;
        } else if (tweetError) {
            return noTweetsJsx("an error occurred retrieving posts");
        } else {
            if (contentItems.length === 0) {
                return noTweetsJsx(`no posts found for date ${dateApi}`);
            }
            const apiTweets: ReactNode[] = [];
            contentItems.forEach((postData, index) => {
                if (!postData.content.data_type !== DataType.News) {
                    apiTweets.push(
                        getPostNode(postData, "border-b-2 border-gray-100 pb-4 pt-4 break-words")
                    );
                }
            });
            return <div className={"pb-4"}>{apiTweets}</div>;
        }
    };

    const getSentimentFilter = () => {
        if (subjectData?.doEnableArxiv) {
            return null
        }
        return (
            <>
                <label
                    htmlFor={'social-stream-sentiment-filter'}
                    className="block text-sm text-gray-500"
                >
                    Sentiment
                </label>
                <select
                    defaultValue={sentiment}
                    onChange={(e) => {
                        onFilterChange()
                        setSentiment(e.target.value as SentimentType)
                    }}
                    name={'social-stream-sentiment-filter'}
                    id={'social-stream-sentiment-filter'}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                >
                    <option value={SentimentType.ANY}>- Any -</option>
                    <option value={SentimentType.POSITIVE}>Positive</option>
                    <option value={SentimentType.NEGATIVE}>Negative</option>
                    <option value={SentimentType.NEUTRAL}>Neutral</option>
                </select>
            </>
        )
    }

    const getTopicOptions = () => {
        const options: JSX.Element[] = []
        options.push(
            <option key={'0'} value={'0'}>- Any -</option>
        )
        if (isSubjectLoading) {
            return options
        }
        const topics = subjectData?.topics
        if (!topics || topics.length === 0) {
            return options
        }
        for (const topic of topics) {
            options.push(
                <option key={`${topic.id}`} value={`${topic.id}`}>{topic.displayLabel}</option>
            )
        }
        return options
    }

    const getDataTypeOptions = () => {
        const options: JSX.Element[] = []
        options.push(
            <option key={'0'} value={'0'}>- Any -</option>
        )
        if (isSubjectLoading) {
            return options
        }
        if (!enabledDataTypes || enabledDataTypes.length === 0) {
            return options
        }
        for (const dataType of enabledDataTypes) {
            options.push(
                <option key={`${dataType.id}`} value={`${dataType.id}`}>{dataType.label}</option>
            )
        }
        return options
    }

    const getTopicFilter = (isSearch = false) => {
        return (
            <>
                <label
                    htmlFor={`social-${isSearch ? 'search' : 'stream'}-topic-filter`}
                    className="block text-sm text-gray-500"
                >
                    Topic
                </label>
                <select
                    defaultValue={`${isSearch ? searchTopicId : topicId}`}
                    onChange={(e) => {
                        onFilterChange()
                        if (isSearch) {
                            setSearchTopicId(parseInt(e.target.value))
                        } else {
                            setTopicId(parseInt(e.target.value))
                        }
                    }}
                    name={`social-${isSearch ? 'search' : 'stream'}-topic-filter`}
                    id={`social-${isSearch ? 'search' : 'stream'}-topic-filter`}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md xl:max-w-[230px] max-w-[200px]"
                >

                    {getTopicOptions()}
                </select>
            </>
        )
    }

    const getAlgoliaFilterText = () => {
        if (!subjectData) {
            return ``
        }
        let filterText = `subject_ids:${subjectData.id}`
        if (searchTopicId) {
            filterText = `${filterText} AND topic_ids:${searchTopicId}`
        }
        if (searchContentTypeFilter) {
            filterText = `${filterText} AND content_type:${searchContentTypeFilter}`
        }
        if (selectedSearchDateRange && selectedSearchDateRange.to && selectedSearchDateRange.from) {
            // Create new Date objects to avoid mutating the original dates
            const startDate = new Date(selectedSearchDateRange.from);
            const endDate = new Date(selectedSearchDateRange.to);

            // Set the time to 00:00:00.000 in the user's local timezone
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            // Add one day to the end date to include the entire end date
            endDate.setDate(endDate.getDate() + 1);

            // Get UTC timestamps in seconds since the Unix epoch
            const startTimestamp = Math.floor(startDate.getTime() / 1000);
            const endTimestamp = Math.floor(endDate.getTime() / 1000);

            // Update the filterText with the date range filters for Algolia
            filterText = `${filterText} AND created_at_timestamp >= ${startTimestamp} AND created_at_timestamp < ${endTimestamp}`;
        }
        return filterText
    }

    const getDataTypeFilter = (isSearch = false) => {
        if (subjectData?.doEnableArxiv) {
            return null
        }
        return (
            <>
                <label
                    htmlFor={`social-${isSearch ? 'search' : 'stream'}-data_type-filter`}
                    className="block text-sm text-gray-500"
                >
                    Source
                </label>
                <select
                    defaultValue={`${isSearch ? searchContentTypeFilter : contentTypeFilter}`}
                    onChange={(e) => {
                        onFilterChange()
                        if (isSearch) {
                            setSearchContentTypeFilter(parseInt(e.target.value))
                        } else {
                            setContentTypeFilter(parseInt(e.target.value))
                        }
                    }}
                    name={`social-${isSearch ? 'search' : 'stream'}-data_type-filter`}
                    id={`social-${isSearch ? 'search' : 'stream'}-data_type-filter`}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md max-w-[230px]"
                >

                    {getDataTypeOptions()}
                </select>
            </>
        )
    }


    const SearchHitComponent = ({hit}) => {
        return (<div>
            <SearchHit hit={hit} wrapperClass={"border-b-2 border-gray-100 pb-4 pt-4 break-words"} />
        </div>);
    }

    const tabs = ['Stream', 'Search'];

    const getStreamSearchTab = () => {
        const streamRender = (<>
            <div className={"flex pt-1 pb-5"}>
                <div className={"flex flex-grow"}>
                    <div className={subjectData?.doEnableArxiv ? "flex-grow p-2" : "p-2"}>
                        {getSentimentFilter()}
                    </div>
                    <div className={"p-2"}>
                        {getDataTypeFilter()}
                    </div>
                    <div className={"p-2"}>
                        {getTopicFilter()}
                    </div>
                    <div className={"pt-6"}>
                        {getCalendar()}
                    </div>
                </div>
            </div>
            <TileRounded
                classArray={["max-h-[800px]", "overflow-auto"]}
                tileType={"inner"}
            >
                <div>{getToggles()}</div>
                <div className={"min-h-[250px]"}>{getTweetRender()}</div>
                <div>{getLoadMoreButton()}</div>
            </TileRounded>
        </>)
        if (!subjectData?.doEnableAlgoliaSearch || !searchClient) {
            return streamRender
        }
        const searchRender = (<div>
            <InstantSearch
                searchClient={searchClient}
                indexName={subjectData.algoliaData!.indexId!}
            >
                <Configure filters={getAlgoliaFilterText()}/>
                <div className={"flex"}>
                    <div className={"grow mt-5"}>
                        <SearchBox
                            placeholder="Search posts..."
                            classNames={{
                                root: 'mt-1 rounded-md shadow-sm w-[400px] pb-4',
                                form: 'flex items-center gap-2', // Creates space between input and button
                                input: 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block flex-1 sm:text-sm border-gray-300 rounded-md',
                                submit: 'flex items-center justify-center',
                                submitIcon: 'w-5 h-5 text-gray-400 hover:text-gray-500'
                            }}
                        />
                    </div>
                    <div className={"px-4"}>
                        {getDataTypeFilter(true)}
                    </div>
                    <div className={"pr-2"}>
                        {getTopicFilter(true)}
                    </div>
                    <div className={"pt-6"}>
                        {getSearchRangeCalendar()}
                    </div>
                </div>
                {/* Add other search components as needed */}
                <TileRounded
                    classArray={["max-h-[800px]", "overflow-auto"]}
                    tileType={"inner"}
                >
                    <div className={"flex"}>
                        <div className={"flex-grow"}>
                            <Stats
                                classNames={{
                                    root: "text-sm text-gray-500"
                                }}
                                translations={{
                                    rootElementText({ nbHits, processingTimeMS, nbSortedHits, areHitsSorted }) {
                                        return areHitsSorted && nbHits !== nbSortedHits
                                            ? `${nbSortedHits!.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()}`
                                            : `${nbHits.toLocaleString()} results found`;
                                    }
                                }}
                            />
                        </div>
                        <div>
                            {(selectedSearchDateRange && selectedSearchDateRange.to && selectedSearchDateRange.from) ? (
                                <div className={"text-sm text-gray-500"}>
                                    {`Showing results from ${new Date(selectedSearchDateRange.from).toLocaleDateString()} to ${new Date(selectedSearchDateRange.to).toLocaleDateString()}`}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <InfiniteHits
                        hitComponent={SearchHitComponent}
                        showPrevious={false}
                        classNames={{
                            loadMore: "mt-4 text-gray-700 bg-white hover:bg-gray-50 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"

                        }}
                    />
                </TileRounded>
            </InstantSearch>
            </div>
        );
        return (<>
            <Tab.Group
                selectedIndex={activeTab === 'stream' ? 0 : 1}
                onChange={(index) => setActiveTab(index === 0 ? 'stream' : 'search')}
            >
                <Tab.List className="flex p-1 mt-2 space-x-1 rounded-md w-[300px]">
                    {tabs.map((tab) => (
                        <Tab
                            key={tab}
                            className={({selected}) =>
                                classNames(
                                    'w-full py-1 text-sm leading-5 font-medium rounded-md',
                                    selected
                                        ? 'bg-white shadow text-gray-700'
                                        : 'text-gray-400 hover:bg-gray-200 hover:text-gray-700'
                                )
                            }
                        >
                            {tab}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {/* Tab Content */}
                    <Tab.Panel>
                        {streamRender}
                    </Tab.Panel>
                    <Tab.Panel>
                        {searchRender}
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </>)
    }

    return (
        <TileBgNone>
            <div className={"flex-grow pt-9 pl-2"}>
                <h2 className={"text-xl"}>{subjectData?.doEnableArxiv ? "arXiv Tracker" : "Social Feed"}</h2>
            </div>
            {getStreamSearchTab()}
        </TileBgNone>
    );
};

export default ContentStream;
